<template>
  <v-row class="app-preview">
    <v-col
      cols="12"
      md="9"
    >
      <v-card class="app-invoice-editable">
        <!-- Header -->
        <div class="py-5 px-5">
          <Letterhead :width="'100%'" />

          <div class="d-flex flex-wrap justify-space-between mt-9">
            <!-- Date -->
            <div class="d-flex align-center">
              <span class="me-2 font-weight-medium text--primary">Date Issued: {{ invoice.created_at }}</span>
            </div>

            <!-- Invoice number -->
            <div
              class="d-flex align-center justify-end"
              :class="{ 'mt-4': $vuetify.breakpoint.xsOnly }"
            >
              <div>
                <v-select
                  v-model="invoice.branch_id"
                  :items="branchOptions"
                  item-value="id"
                  item-text="name"
                  single-line
                  outlined
                  dense
                  hide-details
                  clearable
                  placeholder="Select Branch"
                  :class="{ 'invoice-add-branch': $vuetify.breakpoint.smAndUp }"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-select>
                <RequestHint
                  v-if="request && request.branch && !isApprovedRequest"
                  :is-attr-change="invoice.branch_id !== request.branch.id"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.branch.name"
                />
              </div>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Customer Details -->
        <div class="py-5 px-5">
          <div class="mb-3 d-flex">
            <div class="px-3 flex-grow-1 font-weight-semibold">
              <v-row>
                <!-- Customer Name -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <p class="font-weight-semibold payment-details-header">
                    Invoice To:
                  </p>
                  <v-text-field
                    v-model="invoice.customer_name"
                    single-line
                    dense
                    outlined
                    hide-details
                    placeholder="Customer Name"
                    :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                  ></v-text-field>
                  <RequestHint
                    v-if="request && !isApprovedRequest"
                    :is-attr-change="invoice.customer_name !== request.customer_name"
                    :is-auditor-respond="auditorDisabled"
                    :is-request-void="isRequestVoid"
                    :hint="request.customer_name"
                  />
                </v-col>

                <!-- Customer Contact -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pr-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <p class="font-weight-semibold payment-details-header">
                    Contact No.
                  </p>
                  <v-text-field
                    v-model="invoice.customer_contact_number"
                    single-line
                    dense
                    outlined
                    type="number"
                    :rules="[v => /[0-9\-().\s]{7}$/.test(v) || 'Must be valid contact number']"
                    hide-details="auto"
                    placeholder="Customer Contact Number"
                    prefix="+673"
                    :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                  ></v-text-field>
                  <RequestHint
                    v-if="request && !isApprovedRequest"
                    :is-attr-change="invoice.customer_contact_number !== request.customer_contact_number"
                    :is-auditor-respond="auditorDisabled"
                    :is-request-void="isRequestVoid"
                    :hint="`+673 ${request.customer_contact_number}`"
                  />
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Invoice Details -->
        <div class="py-5 px-5">
          <div class="mb-3 d-none d-md-flex">
            <div class="px-3 flex-grow-1 font-weight-semibold">
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <span>Description</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <span>Amount</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <span>Summable Amount</span>
                </v-col>
              </v-row>
            </div>
            <div class="header-spacer"></div>
          </div>

          <!-- Insurance Category -->
          <v-card
            outlined
            class="d-flex pa-3"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="invoice.insurance_category_id"
                  :items="insuranceCategoryOptions"
                  item-value="id"
                  item-text="name"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Select Insurance Category"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-select>
                <RequestHint
                  v-if="request && request.insurance_category && !isApprovedRequest"
                  :is-attr-change="invoice.insurance_category_id !== request.insurance_category.id"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.insurance_category.name"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- Insurance Product Type -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="invoice.insurance_product_id"
                  :items="insuranceProductOptions"
                  item-value="id"
                  item-text="name"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Select Insurance Product Type"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-select>
                <RequestHint
                  v-if="request && request.insurance_product && !isApprovedRequest"
                  :is-attr-change="invoice.insurance_product_id !== request.insurance_product.id"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.insurance_product.name"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- Car Registration Number -->
          <v-card
            v-if="isMotorInsurance"
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="invoice.car_registration_number"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Car Registration Number"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-text-field>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="invoice.car_registration_number !== request.car_registration_number"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.car_registration_number"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- Insurance -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="invoice.insurance_id"
                  :items="insuranceOptions"
                  item-value="id"
                  item-text="name"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Select Insurance"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-select>
                <RequestHint
                  v-if="request && request.insurance && !isApprovedRequest"
                  :is-attr-change="invoice.insurance_id !== request.insurance.id"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.insurance.name"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- Sum Insured -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Sum Insured
                </p>
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  v-model="invoice.sum_insured"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-text-field>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="invoice.sum_insured !== request.sum_insured"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="`$${formatCurrency(request.sum_insured)}`"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- Insurance Premium -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Insurance Premium
                </p>
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  v-model="invoice.insurance_premium"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-text-field>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="invoice.insurance_premium !== request.insurance_premium"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="`$${formatCurrency(request.insurance_premium)}`"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- After Discount -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <p class="mb-0">
                  After Discount
                </p>
              </v-col>
              <v-col
                cols="12"
                offset-sm="3"
                sm="3"
              >
                <v-text-field
                  v-model="invoice.after_discount"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-text-field>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="invoice.after_discount !== request.after_discount"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="`$${formatCurrency(request.after_discount)}`"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- Road Tax Price -->
          <v-card
            v-if="isMotorInsurance"
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Road Tax Price
                </p>
              </v-col>
              <v-col
                cols="12"
                offset-sm="3"
                sm="3"
              >
                <v-text-field
                  v-model="invoice.road_tax"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-text-field>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="invoice.road_tax !== request.road_tax"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.road_tax ? `$${formatCurrency(request.road_tax)}` : null"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- Pickup Location -->
          <v-card
            v-if="isMotorInsurance"
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="invoice.pickup_location_id"
                  :items="pickupLocationOptions"
                  item-value="id"
                  item-text="name"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Select Road Tax Pickup Location"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-select>
                <RequestHint
                  v-if="request && request.pickup_location && !isApprovedRequest"
                  :is-attr-change="invoice.pickup_location_id !== request.pickup_location.id"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.pickup_location.name"
                />

                <template>
                  <v-text-field
                    v-if="isOtherPickupLocation"
                    v-model="invoice.remarks.pickup_location"
                    single-line
                    dense
                    outlined
                    hide-details="auto"
                    placeholder="Enter Road Tax Pickup Location"
                    class="mt-3"
                    :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                  ></v-text-field>
                  <RequestHint
                    v-if="request && !isApprovedRequest"
                    :is-attr-change="invoice.remarks.pickup_location !== request.pickup_location_remarks"
                    :is-auditor-respond="auditorDisabled"
                    :is-request-void="isRequestVoid"
                    :hint="request.pickup_location_remarks"
                  />
                </template>
              </v-col>
            </v-row>
          </v-card>

          <!-- Additional Fees -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Additional Fees
                </p>
              </v-col>
              <v-col
                cols="12"
                offset-sm="3"
                sm="3"
              >
                <v-text-field
                  v-model="invoice.additional_fees"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-text-field>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="invoice.additional_fees !== request.additional_fees"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.additional_fees ? `$${formatCurrency(request.additional_fees)}` : null"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- General remarks -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="invoice.remarks.general"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="General Remarks"
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                ></v-text-field>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="invoice.remarks.general !== request.general_remarks"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.general_remarks"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- SPD Reference Number -->
          <v-card
            v-if="(invoice.insurance_category_id == 1 || invoice.insurance_category_id == 2) && invoice.insurance_product_id == 1 || (request && (invoice.spd_number !== request.spd_number) && auditorDisabled && !isRequestVoid)"
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="invoice.spd_number"
                  dense
                  outlined
                  maxlength="4"
                  hide-details="auto"
                  placeholder="SPD Reference Number"
                  hint="Enter SPD Reference No. last 4 digits"
                  persistent-hint
                  :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                  @keypress="isNumber"
                ></v-text-field>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="invoice.spd_number !== request.spd_number"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="request.spd_number"
                />
              </v-col>
            </v-row>
          </v-card>

          <!-- Due date -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Due Date
                </p>
              </v-col>
              <v-col
                cols="12"
                offset-sm="3"
                sm="3"
              >
                <v-menu
                  v-model="isDateMenuOpen"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :append-icon="icons.mdiCalendar"
                      :value="new Date(invoice.due_date).toLocaleDateString('en-SG')"
                      readonly
                      dense
                      outlined
                      hide-details
                      v-bind="attrs"
                      :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="invoice.due_date"
                    color="primary"
                    :first-day-of-week="1"
                    @input="isDateMenuOpen = false"
                  >
                  </v-date-picker>
                </v-menu>
                <RequestHint
                  v-if="request && !isApprovedRequest"
                  :is-attr-change="invoice.due_date !== request.due_date"
                  :is-auditor-respond="auditorDisabled"
                  :is-request-void="isRequestVoid"
                  :hint="new Date(request.due_date).toLocaleDateString('en-SG')"
                />
              </v-col>
            </v-row>
          </v-card>
        </div>

        <v-divider></v-divider>

        <!-- Invoice Footer -->
        <div class="py-5 px-5">
          <!-- Issuer -->
          <v-row>
            <v-col
              cols="12"
              sm="6"
              class="d-flex align-center"
            >
              <p class="mb-0 pr-2 font-weight-semibold">
                Issued By:
              </p>
              <p class="mb-0">
                {{ invoice.issuer }}
              </p>
            </v-col>

            <v-col
              cols="4"
              sm="3"
              class="d-flex align-center justify-end"
            >
              <p class="mb-0 font-weight-semibold">
                Total:
              </p>
            </v-col>

            <!-- Total -->
            <v-col
              cols="8"
              sm="3"
              :class="{ 'px-0': $vuetify.breakpoint.xsOnly }"
            >
              <p class="mb-0 text-xl font-weight-semibold">
                ${{ calculatedTotal }}
              </p>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-card
        v-if="!isApprovedRequest"
        class="mb-6"
      >
        <v-card-text>
          <!-- Auditor view request -->
          <v-btn
            v-if="can('write', 'ReportActions') && requestId"
            color="primary"
            depressed
            block
            class="mb-2"
            :disabled="isRespondedRequest || invoice.deleted_at"
            @click="isApproveDialogVisible = true"
          >
            {{ t('Approve Request') }}
          </v-btn>

          <!-- Auditor view request -->
          <v-btn
            v-if="can('write', 'ReportActions') && requestId"
            color="primary"
            outlined
            depressed
            block
            :disabled="isRespondedRequest"
            @click="isRejectDialogVisible = true"
          >
            {{ t('Reject Request') }}
          </v-btn>

          <!-- Create & edit invoice -->
          <v-btn
            v-else-if="can('write', 'ReportActions') && userRole === 'Auditor'"
            color="primary"
            depressed
            block
            :loading="loading"
            :disabled="loading"
            @click="updateInvoice()"
          >
            {{ t('Confirm Invoice') }}
          </v-btn>

          <!-- Void request -->
          <v-btn
            v-else-if="router.currentRoute.params.action === 'Void' || invoice.deleted_at"
            color="error"
            depressed
            block
            :disabled="isApprovedRequest"
            @click="isVoidDialogVisible = true"
          >
            {{ t('Remove Request') }}
          </v-btn>

          <!-- Create & edit request -->
          <v-btn
            v-else
            color="primary"
            depressed
            block
            :loading="loading"
            :disabled="loading || isApprovedRequest"
            @click="isCommentDialogVisible = true"
          >
            {{ t('Send Request') }}
          </v-btn>

          <div
            v-if="invoice.deleted_at"
            class="text-center mt-4"
          >
            {{ can('write', 'ReportActions') ? t('Invoice has been voided, please inform requester to remove this request.')
              : t('Invoice has been voided, please remove this request as it cannot be saved.') }}
          </div>

          <div
            v-else-if="!auditorDisabled && router.currentRoute.params.action !== 'Void'"
            class="text-center mt-4"
          >
            {{ t('Please ensure that all fields are filled correctly as this record will not be editable after confirming.') }}
            <br>
            {{ t('All invoices need to be settled before Sales Report Collection.') }}
          </div>
        </v-card-text>
      </v-card>
      <v-card
        v-if="errors.length != 0"
        class="mb-6"
      >
        <v-card-text>
          <Alert
            :messages="errors"
            color="error"
          ></Alert>
        </v-card-text>
      </v-card>
      <v-card
        v-if="requestId && !isApprovedRequest && can('write', 'ReportActions')"
        class="mb-6"
      >
        <v-card-text v-if="request.requested_by">
          <div v-if="isRequestVoid">
            {{ request.requested_by.nickname }} has requested to void this invoice.
          </div>
          <div v-else-if="isRequestCreate">
            {{ request.requested_by.nickname }} has requested to issue this invoice.
          </div>
          <div v-else>
            <div class="mb-2">
              {{ request.requested_by.nickname }} has requested to edit this invoice.
            </div>
            <div>The <span class="primary--text font-weight-semibold">requested changes</span> is hinted below each invoice field, ensure these changes are appropriate as this invoice will not be reversible after approval.</div>
          </div>
        </v-card-text>
      </v-card>
      <v-card
        v-if="isApprovedRequest"
        class="mb-6"
      >
        <v-card-text>
          <div>
            This request has been approved & no longer editable.
          </div>
        </v-card-text>
      </v-card>
      <RequestComments
        v-if="requestId"
        :comments="comments"
        :fetch-comments="fetchComments"
      />
    </v-col>

    <!-- Void dialog -->
    <v-dialog
      v-model="isVoidDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Void request on {{ request.record_number || invoice.invoice_number }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isVoidDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          This request will be voided. It won't be taken into any calculations.
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="error"
            block
            :loading="voidLoading"
            :disabled="voidLoading"
            class="mt-3"
            @click="voidRequest()"
          >
            Yes, remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Comment dialog -->
    <v-dialog
      v-model="isCommentDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Request changes?
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isCommentDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-textarea
            v-model="newComment"
            dense
            outlined
            hide-details="auto"
            placeholder="Leave your comment here (optional)"
            :disabled="loading"
            class="mb-4"
          ></v-textarea>
          <p>
            {{ requestId ? 'This updates the edit request & changes status to Pending for auditor to respond.' :
              'This action will create an edit request & the changes will be saved after approved by auditor.' }}
          </p>
          <p class="mb-0">
            Please leave any remarks that the auditor should know about the changes.
          </p>
        </v-card-text>

        <v-card-actions class="mt-2">
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            @click="saveRequest()"
          >
            Yes, send request
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Approve dialog -->
    <v-dialog
      v-model="isApproveDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Approve request
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isApproveDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="newComment"
            dense
            outlined
            hide-details="auto"
            placeholder="Leave your comment here (optional)"
            :disabled="loading"
            class="mb-1"
          ></v-text-field>
          This action will update invoice with the request changes, ensure that all fields are filled correctly as this record will not be editable after approval.
        </v-card-text>

        <v-card-actions class="mt-2">
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            @click="approveRequest()"
          >
            Save invoice
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Reject dialog -->
    <v-dialog
      v-model="isRejectDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Reject request
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isRejectDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="newComment"
            dense
            outlined
            hide-details="auto"
            placeholder="Leave your comment here (optional)"
            :disabled="loading"
            class="mb-1"
          ></v-text-field>
          This update the request & change status to Rejected, comment on the reason for rejecting the requested change.
        </v-card-text>

        <v-card-actions class="mt-2">
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            @click="rejectRequest()"
          >
            Reject request
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mdiCalendar, mdiClose } from '@mdi/js'
import {
  inject,
  onUnmounted,
  onMounted,
  computed,
  ref,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { Letterhead } from '@/components/apps/previews'
import RequestHint from '@/components/apps/request/RequestHint'
import RequestComments from '@/components/apps/request/RequestComments'
import Alert from '@/components/Alert'
import invoiceStoreModule from './invoiceStoreModule'

export default {
  components: {
    Letterhead,
    Alert,
    RequestHint,
    RequestComments,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const can = inject('can')
    const { router } = useRouter()
    const requestId = router.currentRoute.params.request_id
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    }

    // Properties
    const invoice = ref({
      created_at: null,
      customer_name: null,
      insurance_category_id: null,
      insurance_product_id: null,
      car_registration_number: null,
      insurance_id: null,
      pickup_location_id: null,
      sum_insured: null,
      insurance_premium: null,
      after_discount: null,
      road_tax: null,
      additional_fees: null,
      spd_number: null,
      remarks: {
        general: null,
        pickup_location: null,
      },
      due_date: null,
      issuer: null,
    })
    const request = ref({})
    const comments = ref([])
    const newComment = ref(null)
    const errors = ref([])
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userRole = userData && userData.role ? userData.role : null
    const insuranceCategoryOptions = ref([])
    const insuranceProductOptions = ref([])
    const insuranceOptions = ref([])
    const pickupLocationOptions = ref([])
    const motorInsuranceId = ref(null)
    const otherPickupLocationId = ref(null)
    const loading = ref(false)
    const voidLoading = ref(false)
    const auditorDisabled = ref(false)
    const voidRequestDisabled = ref(false)
    const isVoidDialogVisible = ref(false)
    const isCommentDialogVisible = ref(false)
    const isApproveDialogVisible = ref(false)
    const isRejectDialogVisible = ref(false)
    const isRequestVoid = ref(false)
    const isRequestCreate = ref(false)
    const isApprovedRequest = ref(false)
    const isRespondedRequest = ref(false)
    const isDateMenuOpen = ref(false)

    // Methods
    const parseCurrency = value => (value === null ? null : value.replace(/[$,]/g, ''))
    const formatCurrency = value => (parseFloat(value)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    const fetchInsuranceCategories = () => {
      store
        .dispatch('app-invoice/fetchInsuranceCategories')
        .then(response => {
          const { data } = response.data
          insuranceCategoryOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurance categories. Please refresh!')
        })
    }
    const fetchInsuranceProducts = () => {
      store
        .dispatch('app-invoice/fetchInsuranceProducts')
        .then(response => {
          const { data } = response.data
          insuranceProductOptions.value = data
          motorInsuranceId.value = insuranceProductOptions.value.filter(product => product.name === 'Motor insurance')[0].id
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurance products. Please refresh!')
        })
    }
    const fetchInsurances = () => {
      store
        .dispatch('app-invoice/fetchInsurances')
        .then(response => {
          const { data } = response.data
          insuranceOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurances. Please refresh!')
        })
    }
    const fetchPickupLocations = () => {
      store
        .dispatch('app-invoice/fetchPickupLocations')
        .then(response => {
          const { data } = response.data
          pickupLocationOptions.value = data
          otherPickupLocationId.value = pickupLocationOptions.value.filter(pickup => pickup.name === 'Others')[0].id
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching pickup location. Please refresh!')
        })
    }
    const fetchInvoice = () => {
      store
        .dispatch('app-invoice/fetchInvoice', { id: router.currentRoute.params.id })
        .then(response => {
          const existingInvoice = response.data.data

          invoice.value.id = existingInvoice.id
          invoice.value.created_at = existingInvoice.created_at
          invoice.value.branch_id = existingInvoice.branch.id
          invoice.value.customer_name = existingInvoice.customer_name
          invoice.value.customer_contact_number = existingInvoice.customer_contact_number
          invoice.value.insurance_category_id = existingInvoice.insurance_category.id
          invoice.value.insurance_product_id = existingInvoice.insurance_product.id
          invoice.value.car_registration_number = existingInvoice.car_registration_number
          invoice.value.insurance_id = existingInvoice.insurance.id
          invoice.value.bluecard_finance_id = existingInvoice.bluecard_finance ? existingInvoice.bluecard_finance.id : null
          invoice.value.pickup_location_id = existingInvoice.pickup_location ? existingInvoice.pickup_location.id : null
          invoice.value.sum_insured = parseCurrency(existingInvoice.sum_insured)
          invoice.value.insurance_premium = parseCurrency(existingInvoice.insurance_premium)
          invoice.value.after_discount = parseCurrency(existingInvoice.after_discount)
          invoice.value.road_tax = parseCurrency(existingInvoice.road_tax)
          invoice.value.additional_fees = parseCurrency(existingInvoice.additional_fees)
          invoice.value.spd_number = existingInvoice.spd_number
          invoice.value.remarks.general = existingInvoice.general_remarks
          invoice.value.remarks.bluecard_finance = existingInvoice.bluecard_finance_remarks
          invoice.value.remarks.pickup_location = existingInvoice.pickup_location_remarks
          invoice.value.due_date = new Date(existingInvoice.due_date.replace(/(..)\/(..)\/(....)/, '$3-$2-$1')).toISOString().substring(0, 10)
          invoice.value.issuer = existingInvoice.issuer.nickname
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching invoice. Please refresh!')
        })
    }
    const updateInvoice = () => {
      store
        .dispatch('app-invoice/updateInvoice', { id: router.currentRoute.params.id, invoice: invoice.value })
        .then(response => {
          const { data } = response.data
          router.push(`/apps/invoice/preview/${data.id}`)
          loading.value = false
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while updating invoice. Please refresh!')
        })
    }
    const isNumber = event => {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      const keyPressed = event.key
      if (!keysAllowed.includes(keyPressed)) {
        event.preventDefault()
      }
    }
    const createComment = id => {
      store
        .dispatch('requestStore/createComment', {
          user_id: userData.id,
          approval_request_id: id || requestId,
          comment: newComment.value,
        })
        .then(response => {
          snackbarService.success(response.data.message)
          router.push('/apps/requests/list')
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while saving comment. Please refresh!')
        })
    }
    const reassignEmptyString = () => {
      const attributes = ['additional_fees', 'road_tax']
      attributes.forEach(attr => {
        if (invoice.value[attr] === null) invoice.value[attr] = ''
      })
    }
    const saveRequest = () => {
      loading.value = true
      delete invoice.value.issuer
      reassignEmptyString()
      const requestParam = {
        record_id: invoice.value.id,
        record_type: 'Invoice',
        requested_by_id: userData.id,
        action: isRequestCreate.value ? 'Create' : 'Edit',
        params: invoice.value,
        rejected_at: null,
        responded_by: null,
        comment: newComment.value,
      }
      if (requestId) requestParam.id = requestId
      store
        .dispatch(`requestStore/${requestId ? 'updateRequest' : 'createRequest'}`, requestParam)
        .then(response => {
          if (newComment.value) {
            createComment(response.data.data.id)
          } else {
            snackbarService.success(response.data.message)
            router.push('/apps/requests/list')
          }
        })
        .catch(error => {
          loading.value = false
          isCommentDialogVisible.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while saving request. Please refresh!')
        })
    }
    const fetchRequest = () => {
      store
        .dispatch('requestStore/fetchRequest', { id: requestId })
        .then(response => {
          let invoiceAttrsInField = response.data.data.record
          if (response.data.data.approved_at) {
            isApprovedRequest.value = true
            invoiceAttrsInField = response.data.data.result
          }
          const existingInvoice = invoiceAttrsInField
          invoice.value.id = response.data.data.record.id
          invoice.value.created_at = existingInvoice.created_at
          invoice.value.branch_id = existingInvoice.branch.id
          invoice.value.customer_name = existingInvoice.customer_name
          invoice.value.customer_contact_number = existingInvoice.customer_contact_number
          invoice.value.insurance_category_id = existingInvoice.insurance_category.id
          invoice.value.insurance_product_id = existingInvoice.insurance_product.id
          invoice.value.car_registration_number = existingInvoice.car_registration_number
          invoice.value.insurance_id = existingInvoice.insurance.id
          invoice.value.bluecard_finance_id = existingInvoice.bluecard_finance ? existingInvoice.bluecard_finance.id : null
          invoice.value.pickup_location_id = existingInvoice.pickup_location ? existingInvoice.pickup_location.id : null
          invoice.value.sum_insured = parseCurrency(existingInvoice.sum_insured)
          invoice.value.insurance_premium = parseCurrency(existingInvoice.insurance_premium)
          invoice.value.after_discount = parseCurrency(existingInvoice.after_discount)
          invoice.value.road_tax = parseCurrency(existingInvoice.road_tax)
          invoice.value.additional_fees = parseCurrency(existingInvoice.additional_fees)
          invoice.value.spd_number = existingInvoice.spd_number
          invoice.value.remarks.general = existingInvoice.general_remarks
          invoice.value.remarks.bluecard_finance = existingInvoice.bluecard_finance_remarks
          invoice.value.remarks.pickup_location = existingInvoice.pickup_location_remarks
          invoice.value.due_date = new Date(existingInvoice.due_date.replace(/(..)\/(..)\/(....)/, '$3-$2-$1')).toISOString().substring(0, 10)
          invoice.value.issuer = existingInvoice.issuer.nickname
          invoice.value.deleted_at = existingInvoice.deleted_at

          if (response.data.data.result) {
            request.value = response.data.data.result
            request.value.due_date = new Date(request.value.due_date.replace(/(..)\/(..)\/(....)/, '$3-$2-$1')).toISOString().substring(0, 10)
            request.value.sum_insured = parseCurrency(request.value.sum_insured)
            request.value.insurance_premium = parseCurrency(request.value.insurance_premium)
            request.value.after_discount = parseCurrency(request.value.after_discount)
            request.value.road_tax = parseCurrency(request.value.road_tax)
            request.value.additional_fees = parseCurrency(request.value.additional_fees)
          }
          request.value.requested_by = response.data.data.requested_by
          request.value.record_number = response.data.data.record.invoice_number
          isRequestVoid.value = response.data.data.action === 'Void'
          isRequestCreate.value = response.data.data.action === 'Create'
          if (response.data.data.responded_by) isRespondedRequest.value = true
          if (response.data.data.approved_at) isApprovedRequest.value = true
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching request. Please refresh!')
        })
    }
    const fetchRequestedInvoice = () => {
      store
        .dispatch('requestStore/fetchRequest', { id: requestId })
        .then(response => {
          const returnedInvoice = response.data.data.result

          invoice.value.id = response.data.data.record.id
          invoice.value.created_at = returnedInvoice.created_at
          invoice.value.invoice_number = returnedInvoice.invoice_number
          invoice.value.branch_id = returnedInvoice.branch.id
          invoice.value.customer_name = returnedInvoice.customer_name
          invoice.value.customer_contact_number = returnedInvoice.customer_contact_number
          invoice.value.insurance_category_id = returnedInvoice.insurance_category.id
          invoice.value.insurance_product_id = returnedInvoice.insurance_product.id
          invoice.value.car_registration_number = returnedInvoice.car_registration_number
          invoice.value.insurance_id = returnedInvoice.insurance.id
          invoice.value.bluecard_finance_id = returnedInvoice.bluecard_finance ? returnedInvoice.bluecard_finance.id : null
          invoice.value.pickup_location_id = returnedInvoice.pickup_location ? returnedInvoice.pickup_location.id : null
          invoice.value.sum_insured = parseCurrency(returnedInvoice.sum_insured)
          invoice.value.insurance_premium = parseCurrency(returnedInvoice.insurance_premium)
          invoice.value.after_discount = parseCurrency(returnedInvoice.after_discount)
          invoice.value.road_tax = parseCurrency(returnedInvoice.road_tax)
          invoice.value.additional_fees = parseCurrency(returnedInvoice.additional_fees)
          invoice.value.spd_number = returnedInvoice.spd_number
          invoice.value.remarks.general = returnedInvoice.general_remarks
          invoice.value.remarks.bluecard_finance = returnedInvoice.bluecard_finance_remarks
          invoice.value.remarks.pickup_location = returnedInvoice.pickup_location_remarks
          invoice.value.due_date = new Date(returnedInvoice.due_date.replace(/(..)\/(..)\/(....)/, '$3-$2-$1')).toISOString().substring(0, 10)
          invoice.value.issuer = returnedInvoice.issuer.nickname
          if (response.data.data.approved_at) isApprovedRequest.value = true
          invoice.value.deleted_at = returnedInvoice.deleted_at
          isRequestCreate.value = response.data.data.action === 'Create'
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching request. Please refresh!')
        })
    }
    const approveRequest = () => {
      loading.value = true
      store
        .dispatch('requestStore/approveRequest', { id: requestId, comment: newComment.value })
        .then(response => {
          if (newComment.value) {
            createComment()
          } else {
            snackbarService.success(response.data.message)
            router.push('/apps/requests/list')
          }
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while approving request. Please refresh!')
        })
    }
    const rejectRequest = () => {
      loading.value = true
      store
        .dispatch('requestStore/rejectRequest', { id: requestId, comment: newComment.value })
        .then(response => {
          if (newComment.value) {
            createComment()
          } else {
            snackbarService.success(response.data.message)
            router.push('/apps/requests/list')
          }
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while rejecting request. Please refresh!')
        })
    }
    const voidRequest = () => {
      voidLoading.value = true
      store
        .dispatch('requestStore/voidRequest', { id: requestId })
        .then(response => {
          snackbarService.success(response.data.message)
          router.push('/apps/requests/list')
        })
        .catch(error => {
          voidLoading.value = false
          snackbarService.error(error.data.message || 'Something went wrong while voiding request. Please refresh!')
        })
    }
    const fetchComments = () => {
      store
        .dispatch('requestStore/fetchComments', { request_id: requestId })
        .then(response => {
          const { data } = response.data
          comments.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching comments. Please refresh!')
        })
    }
    const isInvoiceEdit = () => {
      if (requestId && can('write', 'ReportActions')) {
        // Auditor view request
        auditorDisabled.value = true
        fetchRequest()
      } else if (router.currentRoute.params.action === 'Void') {
        voidRequestDisabled.value = true
        fetchRequest()
      } else if (requestId) {
        // Clerk edit request
        fetchRequestedInvoice()
      } else {
        // Edit invoice / create request
        fetchInvoice()
      }
    }

    // Computed
    const branchOptions = computed(() => store.state.branches.filter(b => b.active))
    const isMotorInsurance = computed(() => (motorInsuranceId.value === invoice.value.insurance_product_id))
    const isOtherPickupLocation = computed(() => {
      if (otherPickupLocationId.value !== invoice.value.pickup_location_id) invoice.value.remarks.pickup_location = null

      return otherPickupLocationId.value === invoice.value.pickup_location_id
    })
    const calculatedTotal = computed(() => {
      const afterDiscount = parseFloat(invoice.value.after_discount) || 0.00
      const roadTax = parseFloat(invoice.value.road_tax) || 0.00
      const additionalFees = parseFloat(invoice.value.additional_fees) || 0.00

      return parseFloat(afterDiscount + roadTax + additionalFees).toFixed(2)
    })

    // Mounted
    onMounted(() => {
      if (requestId) fetchComments()
      isInvoiceEdit()
      fetchInsuranceCategories()
      fetchInsuranceProducts()
      fetchInsurances()
      fetchPickupLocations()
    })

    // Unmounted
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    return {
      t,
      can,
      router,
      invoice,
      errors,
      branchOptions,
      insuranceCategoryOptions,
      insuranceProductOptions,
      insuranceOptions,
      pickupLocationOptions,
      isMotorInsurance,
      isOtherPickupLocation,
      calculatedTotal,
      updateInvoice,
      loading,
      voidLoading,
      auditorDisabled,
      voidRequestDisabled,
      isRequestVoid,
      isRequestCreate,
      isApprovedRequest,
      isRespondedRequest,
      isDateMenuOpen,
      isNumber,
      formatCurrency,
      request,
      saveRequest,
      approveRequest,
      rejectRequest,
      voidRequest,
      requestId,
      newComment,
      comments,
      fetchComments,
      isVoidDialogVisible,
      isCommentDialogVisible,
      isApproveDialogVisible,
      isRejectDialogVisible,
      userRole,

      icons: {
        mdiCalendar,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/style.scss';

.app-invoice-editable {
  .invoice-add-branch {
    width: 15rem;
  }
}
</style>
